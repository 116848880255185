/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import CodeSnippet from './CodeSnippet'
import ModalImage from 'react-modal-image'
import { v4 as uuidv4 } from 'uuid'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const blogbody = ({ blogState, body }) => {
    function modifyText(text) {
        // console.log(text)
        return text
            .toString()
            .split(`\n`)
            .reduce((children, textSegment, index) => {
                // Replace spaces with jsSpace code
                var textSpaced = textSegment.replace(/ /g, '\xa0')
                // Single line code
                if (text.toString().split('\n').length === 1) {
                    return [
                        ...children,
                        index > 0 && <br key={uuidv4()} />,
                        // Filter for empty values
                        textSpaced !== '' ? <code key={uuidv4()}>{textSpaced}</code> : null,
                    ]
                } else {
                    // Multi line codes
                    return [
                        ...children,
                        index > 0 && <pre key={uuidv4()} />,
                        // Filter for empty values
                        textSpaced !== '' ? (
                            <pre key={uuidv4()}>{textSpaced}</pre>
                        ) : (
                            <pre key={uuidv4()}></pre>
                        ),
                    ]
                }
            }, [])
    }

    // Add Id's to headings
    // Making the matching based on stored state text
    const handleID = (headingsTwo, children) => {
        if (blogState.headingsTwo.length > 0) {
            let tempIndex = 0
            headingsTwo.map((heading, index) => {
                let stringOfChildren
                // Slicing to take care of extra spacing
                if (children[0].length > 0) {
                    stringOfChildren = children[0].slice(0, -1)
                }
                if (
                    heading === children[0] ||
                    heading === stringOfChildren ||
                    heading === children[0]?.props?.children
                ) {
                    tempIndex = index
                }
                // Checking for more complex H2's
                if (children.length > 1) {
                    let tempString = ''
                    children.map((child) => {
                        if (child.props) {
                            tempString += child.props.children
                        } else {
                            tempString += child
                        }
                    })
                    if (
                        heading === tempString ||
                        heading === tempString.slice(0, -1) ||
                        heading === tempString.slice(0, -2)
                    ) {
                        tempIndex = index
                    }
                }
            })
            return 'heading' + tempIndex
        } else {
            return 'heading'
        }
    }

    const Ηeading1 = ({ children }) => <h1 style={{ paddingTop: '1.7rem' }}>{children}</h1>
    const Ηeading2 = ({ children }) => (
        <h2 id={handleID(blogState.headingsTwo, children)} className='pb-0 pt-5 heading-toc'>
            <div className='pt-3 mobile-margin-top'></div>
            {children}
        </h2>
    )

    const Ηeading3 = ({ children }) => <h3 style={{ letterSpacing: 'normal' }}>{children}</h3>

    const options = {
        renderMark: {
            [MARKS.CODE]: (text) => {
                if (!text.props) {
                    return modifyText(text)
                } else {
                    return modifyText(text.props.children)
                }
            },
        },
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                // console.log(node)
                // Image Rendering
                if (node.data.target) {
                    return (
                        <ModalImage
                            className='w-80 mx-auto d-flex my-3 py-2'
                            small={node.data.target.file.url}
                            large={node.data.target.file.url}
                            alt={node.data.target.title}
                        />
                    )
                }
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                if (node.data.target) {
                    if (node.data.target.internal.type === 'ContentfulCodeSnippet') {
                        return (
                            <CodeSnippet
                                markdown={node.data.target.code.code}
                                language={`${node.data.target.language.toLowerCase()}`}
                                style={`${node.data.target.style ? node.data.target.style : ''}`}
                            />
                        )
                    }
                    if (node.data.target.internal.type === 'ContentfulVideo') {
                        return (
                            <Fragment>
                                <div className='cloud-aws-msk-video'>
                                    <iframe
                                        src={`https://www.youtube.com/embed/${node.data.target.videoId}`}
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        frameBorder='0'
                                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                                        allowFullScreen></iframe>
                                </div>
                            </Fragment>
                        )
                    }
                }
            },
            [BLOCKS.HEADING_1]: (node, children) => <Ηeading1>{children}</Ηeading1>,
            [BLOCKS.HEADING_2]: (node, children) => <Ηeading2>{children}</Ηeading2>,
            [BLOCKS.HEADING_3]: (node, children) => <Ηeading3>{children}</Ηeading3>,
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className='blog-line-height text-justify' key={uuidv4()}>
                    {children}
                </p>
            ),
            [BLOCKS.OL_LIST]: (node, children) => (
                <ol>
                    {children.map((child) => {
                        return child
                    })}
                </ol>
            ),
            [BLOCKS.UL_LIST]: (node, children) => (
                <ul>
                    {children.map((child) => {
                        return child
                    })}
                </ul>
            ),
            // Rest of cases
            // HEADING_3
            // HEADING_4
            // HEADING_5
            // HEADING_6
            [INLINES.HYPERLINK]: (node) => {
                // console.log(node)
                if (node.data.uri.includes('player.vimeo.com/video')) {
                    return (
                        <Fragment>
                            <div className='cloud-aws-msk-video'>
                                <iframe
                                    src={node.data.uri}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    frameBorder='0'
                                    allow='autoplay; fullscreen'
                                    allowFullScreen></iframe>
                            </div>
                        </Fragment>
                    )
                } else if (
                    node.data.uri.includes('lenses.io') ||
                    node.data.uri.includes('landoop.com')
                ) {
                    return (
                        <a className='blog-link' href={node.data.uri}>
                            {node.content.map((textValue) => {
                                return textValue.value
                            })}
                        </a>
                    )
                } else {
                    return (
                        <a
                            className='blog-link'
                            rel='noopener noreferrer'
                            target='_blank'
                            href={node.data.uri}>
                            {node.content.map((textValue) => {
                                return textValue.value
                            })}
                        </a>
                    )
                }
            },
        },
    }

    return <div>{renderRichText(body, options)}</div>
}
export default blogbody

blogbody.propTypes = {
    blogState: PropTypes.object,
    body: PropTypes.object,
}
